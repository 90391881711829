<template>
  <div>
    <h1>DevTable</h1>
    <b-button @click="addRow">Add Row</b-button>

    <hr class="my-1"/>
    <b-container>
      <h2> Items: All Years </h2>
      <b-row v-for="item in items" :key="item.id" cols="12">
        <b-col cols="3">
          {{ item.id }}
        </b-col>
        <b-col>
          {{ item.name }}
        </b-col>
        <b-col>
          {{ item.year }}
        </b-col>
        <b-col>
          {{ item.createdAt }}
        </b-col>
        <b-col cols="3">
          <b-button @click="updateRow(item)" size="sm" class="mb-1">Update</b-button>
        </b-col>
      </b-row>

      <hr class="my-1"/>
      <h2> Items: {{ year }} </h2>
      <b-row v-for="item in itemsByYear" :key="`year-${item.id}`" cols="12">
        <b-col cols="4">
          {{ item.id }}
        </b-col>
        <b-col cols="4">
          {{ item.name }}
        </b-col>
        <b-col cols="4">
          {{ item.year }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {uuid} from 'vue-uuid';
import {API, graphqlOperation} from 'aws-amplify';
import {listDevs, listDevsByYear} from '@/graphql/queries';
import {createDev, updateDev} from '@/graphql/mutations';

export default {
  name: 'DevTable',
  data() {
    return {
      year: 2024,
      selectedYear: null,
      items: [],
      itemsByYear: []
    }
  },
  async mounted() {
    await this.listDev();
    await this.listDevByYear(this.year);
  },
  methods: {
    async addRow() {
      const data = {
        id: uuid.v4(),
        name: 'test',
        year: this.selectedYear,
      };

      const response = await API.graphql(graphqlOperation(createDev, {input: data}));
      if(response.data.createDev) {
        this.items.push(response.data.createDev)
      }
    },
    async updateRow(item) {
      item.year = this.year
      item.createdAt = new Date().toISOString()
      const input = {
        id: item.id,
        year: item.year,
        createdAt: item.createdAt
      }

      const response = await API.graphql(graphqlOperation(updateDev, {input: input }));
      if(response.data.updateDev) {
        //console.log('updated', response.data.updateDev)
      }
    },
    async listDev() {
      let nextToken = null;
      const items = [];
      do {
        const input = {
          limit: 1000,
          nextToken: nextToken
        };
        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listDevs, input));
        items.push(...response.data.listDevs.items);
        nextToken = response.data.listDevs.nextToken;
      }
      while (nextToken);
      this.items = items;
    },

    async listDevByYear(year = new Date().getFullYear()) {
      let nextToken = null;
      const items = [];
      do {
        const input = {
          year: year,
          limit: 1000,
          nextToken: nextToken

        };
        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(listDevsByYear, input));
        items.push(...response.data.listDevsByYear.items);
        nextToken = response.data.listDevsByYear.nextToken;
      }
      while (nextToken);
      this.itemsByYear = items;
    }

  }
}
</script>



<style scoped>

</style>
